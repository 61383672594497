import PageContainer from 'components/layout/PageContainer';
import { useLazyQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { Alert, Skeleton, Card } from 'antd';
import { getMessageFromGraphQLError, grabFirstGQLDataResult } from 'utils/helpers';
import {
  adminCategoryListQuery,
  adminCheckAllItemPricingFormulasQuery,
  adminLibraryCategoriesQuery,
  adminShowDigitsQuery,
} from 'graphql/queries';
import { PlusOutlined, ImportOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { createCategory as _createCategory, createCommonCategory } from 'graphql/methods';
import { useMemo, useState, memo, useRef, useCallback } from 'react';
import { useLibraryContext } from 'contexts/LibraryContext';
import useCurrentUser from 'hooks/auth/useCurrentUser';
import EmptyBox from 'components/common/EmptyBox';
import equal from 'fast-deep-equal/es6/react';
import { BiRefresh } from 'react-icons/bi';
import { AllApolloCategoriesContext } from 'components/user/shoppingCart/context';
import { useCachedQuery } from 'graphql/utils';
import { ShowDigitsProvider } from 'contexts/ShowDigitsContext';
import CatalogueConfigurationDraggable from './CatalogueConfigurationDraggable';
import classes from './CatalogueConfiguration.module.less';
import ItemEditModal from './itemModal/ItemEditModal';
import CatalogueConfigurationHelperWidget from './CatalogueConfigurationHelperWidget';
import ImportCategoryPresets from './ImportCategoryPresetsModal';

const skeletons = [...new Array(2)].map((_, i) => i + 1);

function CatalogueConfigurationContent() {
  const { t } = useTranslation();
  const { isLibrary } = useLibraryContext();
  const me = useCurrentUser();

  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useCachedQuery(isLibrary ? adminLibraryCategoriesQuery : adminCategoryListQuery, {
    fetchPolicy: me?.[0]?.role?.includes('ADMIN') ? 'network-only' : 'cache-first', // force refresh if admin changed something to force load changes
  });
  const categories = useMemo(() => grabFirstGQLDataResult(categoriesData), [categoriesData]);

  const {
    data: showDigitsData,
    loading: showDigitsLoading,
    error: showDigitsError,
  } = useCachedQuery(adminShowDigitsQuery, {
    fetchPolicy: me?.[0]?.role?.includes('ADMIN') ? 'network-only' : 'cache-first', // force refresh if admin changed something to force load changes
  });
  const showDigits = useMemo(
    () => grabFirstGQLDataResult(showDigitsData)?.shoppingCartPreferences?.showDigits,
    [showDigitsData],
  );

  const { isAllData, loading, error } = useMemo(
    () => ({
      isAllData: !!categoriesData && !!showDigitsData,
      loading: categoriesLoading && showDigitsLoading,
      error: categoriesError || showDigitsError,
    }),
    [categoriesData, categoriesError, categoriesLoading, showDigitsData, showDigitsError, showDigitsLoading],
  );
  const allApolloCategoriesRef = useRef();
  allApolloCategoriesRef.current = categories;
  const getAllApolloCategories = useCallback(() => allApolloCategoriesRef.current, []);

  return (
    <ShowDigitsProvider value={showDigits}>
      <ItemEditModal getAllApolloCategories={getAllApolloCategories} />
      {error ? <Alert type="error" message={getMessageFromGraphQLError(error)} /> : null}
      {skeletons.map((k) => (
        <Skeleton title loading={!isAllData && loading} active key={k} />
      ))}
      <div className={classes.categoryList}>
        <AllApolloCategoriesContext.Provider value={getAllApolloCategories}>
          <CatalogueConfigurationDraggable categories={categories} />
        </AllApolloCategoriesContext.Provider>
      </div>
      {isEmpty(categories) && !loading && !error ? (
        <Card>
          <EmptyBox label={t('admin.CatalogueConfiguration.emptyCategoryListMessage')} />
        </Card>
      ) : null}
    </ShowDigitsProvider>
  );
}
const CatalogueConfigurationContentMemo = memo(CatalogueConfigurationContent, equal);

function CatalogueConfigurationPage() {
  const { isLibrary } = useLibraryContext();
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const createCategory = isLibrary ? createCommonCategory : _createCategory;

  const showModal = () => setIsModalVisible(true);
  const [checkAllItems, { data }] = useLazyQuery(adminCheckAllItemPricingFormulasQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { isLibrary },
  });
  const validation = useMemo(() => grabFirstGQLDataResult(data), [data]);

  const ActionButtons = [
    {
      icon: <PlusOutlined />,
      onClick: createCategory,
      type: 'primary',
      label: t('admin.CatalogueConfiguration.addCategory'),
    },
    {
      icon: <ImportOutlined />,
      onClick: showModal,
      label: t('admin.CatalogueConfiguration.importCategoryPresets'),
      hide: isLibrary,
    },
  ];

  const MenuItems = [
    {
      icon: <BiRefresh />,
      onClick: checkAllItems,
      label: t('admin.CatalogueConfiguration.itemsValidation.button'),
    },
  ];

  return (
    <PageContainer
      title={isLibrary ? t('admin.CatalogueConfiguration.titleCommonLibrary') : t('admin.CatalogueConfiguration.title')}
      menuItems={MenuItems}
      actionButtons={ActionButtons}
      left={<CatalogueConfigurationHelperWidget />}
    >
      <div style={{ position: 'relative' }}>
        <div className="xs-mt-20">
          {!validation?.isValid && validation?.errors?.length ? (
            <Alert
              type="error"
              className="margin-bottom-16"
              message={validation?.errors?.map((e) => (
                <div className="textTransformFirstLetterCapitalize">
                  {t('admin.CatalogueConfiguration.itemsValidation.error', e)}
                </div>
              ))}
            />
          ) : (
            !!data && (
              <Alert
                type="success"
                className="margin-bottom-16"
                message={
                  <div className="textTransformFirstLetterCapitalize">
                    {t('admin.CatalogueConfiguration.itemsValidation.success')}
                  </div>
                }
              />
            )
          )}
          <CatalogueConfigurationContentMemo />
          <ImportCategoryPresets visible={isModalVisible} handleClose={setIsModalVisible} />
        </div>
      </div>
    </PageContainer>
  );
}

export default memo(CatalogueConfigurationPage, equal);
